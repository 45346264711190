<!-- eslint-disable vue/valid-v-slot -->
<template>
  <Layout>
    <v-row>
      <v-col cols="12">
        <v-btn color="teal lighten-1 float-right" depressed dark>
          <v-icon color="white">mdi-account-plus</v-icon>
          <span class="white--text ml-1" @click="newClient"
            >New Shareholder</span
          >
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card class="elevation-0" outlined>
          <v-data-table
            :headers="headers"
            :loading="loading"
            loading-text="Loading shareholders..."
            :items="shareholders"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Shareholders</v-toolbar-title>
                <v-divider vertical class="mx-4"></v-divider>
                <div class="flex-grow-1"></div>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <div class="flex-grow-1"></div>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click.stop="showPreview(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View shareholder</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="editItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit shareholder</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <p>No data to display</p>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
     <ViewClient
      :is-open="viewDialogOpen"
      :client="viewDialogClient"
      @onCloseDialog="viewDialogOpen = false"
    />
  </Layout>
</template>
<script>
import Layout from '@layouts/main'
import ViewClient from '@components/Clients/ViewClient.vue'
import api from '@src/api/index.js'
import EventBus from '@utils/EventBus'
import { EventBusEvents, ClientTypes } from '@src/constants/index.js'

export default {
  name: 'Shareholders',
  components: {
    Layout,
    ViewClient,
  },
  data: () => ({
    viewDialogOpen: false,
    viewDialogClient: null,
    users: [],
    files: [],
    clients: [],
    search: '',
    saveDisabled: true,
    selected: [],
    shareholders: [],
    editedIndex: -1,
    headers: [
      {
        text: 'Shareholder',
        value: 'name',
        align: 'left',
      },
      {
        text: 'Type',
        value: 'shareholderType',
        align: 'left',
      },
      {
        text: 'Email',
        value: 'email',
        align: 'left',
      },
      {
        text: 'Mobile',
        value: 'mobileNumber',
        align: 'left',
      },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
      },
    ],
    loading: false,
  }),
  mounted() {
    this.getAllShareholders()
  },
  methods: {
    getClientName({ name, shareholderType, companyName }) {
      return shareholderType === ClientTypes.INDIVIDUAL ? name : companyName
    },
    enableSave(value) {
      this.saveDisabled = value
    },
    filter(value, search, item) {
      const { name, shareholderType, companyName, email } = item
      const shareholderName =
        shareholderType === ClientTypes.INDIVIDUAL ? name : companyName

      return (
        shareholderName.toLowerCase().includes(search.toLowerCase()) ||
        email.toLowerCase().includes(search.toLowerCase()) ||
        shareholderType.toLowerCase().includes(search.toLowerCase())
      )
    },
    resetModal() {
      EventBus.$emit(EventBusEvents.RESET_NEW_CLIENT, {}, this)
    },
    showPreview(item) {
      this.viewDialogOpen = true
      this.viewDialogClient = item
    },
    newClient() {
      this.$router.push({ name: 'new-shareholder' })
    },
    async editItem(item) {
      const { clientId } = item
      try {
        const lockId = await api.lockEditClient(clientId)
        this.$router.push({
          name: 'edit-shareholder',
          params: { item, lockId },
        })
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    async getAllShareholders() {
      this.loading = true
      try {
        const shareholders = await api.getAllShareholders()
        shareholders.forEach(element => {
          if(element) {
            if(element.companyName) {
              element.name=element.companyName;
            }
          }
        });
        this.shareholders = shareholders
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style>
.fixed--bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
</style>
